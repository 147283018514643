import { FormEvent, useState, useEffect } from 'react';
import {
    Facet,
    useMakeFacetSearchQuery,
    useCascadingFacetSearchQuery,
    useSubmitFormMutation
} from '../../graphql/generated';
import {
    InputField,
    SelectField,
    SelectFacetField
} from './FormFields';
import { Alert } from '../Alert';

export interface Regions {
    value: string
}

export type FacetOptions = 'make' | 'model' | 'year' | 'badge';

export interface FacetSearchParams {
    facet: FacetOptions;
    make: string;
    model: string;
    year: string;
    badge: string;
}

export function Form() {
    const [name, setName] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [state, setState] = useState<string>('NSW');
    const [facetSearchFields, setFacetSearchFields] = useState<FacetSearchParams>({
        facet: 'make',
        make: '',
        model: '',
        year: '',
        badge: ''
    });
    const [kilometres, setKilometres] = useState<string>('');
    const [rego, setRego] = useState<string>('');
    const [regoState, setRegoState] = useState<string>('NSW');
    const [makeData, setMakeData] = useState<Facet[]>([]);
    const [modelData, setModelData] = useState<any>([]);
    const [yearData, setYearData] = useState<any>([]);
    const [badgeData, setBadgeData] = useState<any>([]);
    const [formAlert, setFormAlert] = useState<string | null>(null);

    const auStates: Regions[] = [
        { value: 'NSW' },
        { value: 'NT' },
        { value: 'QLD' },
        { value: 'SA' },
        { value: 'TAS' },
        { value: 'VIC' },
        { value: 'WA' },
    ];

    const fieldDivLeft: string = 'flex flex-col mb-4 md:w-1/2 md:pr-4';
    const fieldDivRight: string = 'flex flex-col mb-4 md:w-1/2 md:pl-4';
    const formInput: string = 'border border-ea-gray-blue h-10 rounded-md w-full';

    const { data: makeFacetData } = useMakeFacetSearchQuery();
    const { data: cascadingFacetData } = useCascadingFacetSearchQuery({
        variables: {
            input: facetSearchFields
        }
    });

    const [submitForm] = useSubmitFormMutation();

    useEffect(() => {
        if (!makeFacetData?.make_facet_search) {
            return
        }
        setMakeData(makeFacetData?.make_facet_search.makeResults);
    }, [makeFacetData?.make_facet_search]);

    console.log('make facet search results: ', makeFacetData?.make_facet_search);

    useEffect(() => {
        setFormAlert(null); 
    
        if (!cascadingFacetData?.cascading_facet_search) {
            return
        }

        switch (facetSearchFields.facet) {
            case 'model':
                setModelData(cascadingFacetData.cascading_facet_search);
                setYearData([]);
                setBadgeData([]);
                break;
            case 'year':
                setYearData(cascadingFacetData.cascading_facet_search);
                setBadgeData([]);
                break;
            case 'badge':
                if (badgeData.length === 0) {
                    setBadgeData(cascadingFacetData.cascading_facet_search);
                }
                break;
        }
    }, [cascadingFacetData?.cascading_facet_search])

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormAlert(null);
        const { make, model, year, badge } = facetSearchFields;
        if (!name || !phoneNumber || !email || !state || !make || !model
            || !year || !badge || !kilometres || !rego || !regoState) {
            setFormAlert('Please fill out missing fields');
            return;
        }
        try {
            await submitForm({
                variables: {
                    input: {
                        name,
                        phoneNumber,
                        email,
                        state,
                        make,
                        model,
                        year,
                        badge,
                        kilometres,
                        rego,
                        rego_state: regoState
                    }
                }
            })
            setFormAlert('success');
        } catch (err: any) {
            setFormAlert(err.message || 'An error occurred - please try again.')
        }
    }

    return (
        <div className='w-full p-7 md:px-32'>
            <h1 className='block w-full text-ea-blue font-bold text-h4'>We will buy your car today</h1>
            <form onSubmit={handleSubmit}
                className='mb-4 py-6 md:flex md:flex-wrap md:justify-between max-w-2xl'>
                <InputField labelName='Name'
                    parentClass={fieldDivLeft}
                    inputClass={`${formInput} p-2.5`}
                    inputId='name'
                    value={name}
                    setValue={setName}
                    formAlert={formAlert}
                    setFormAlert={setFormAlert}
                />
                <InputField labelName='Phone Number'
                    parentClass={fieldDivRight}
                    inputClass={`${formInput} p-2.5`}
                    inputId='phone'
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    formAlert={formAlert}
                    setFormAlert={setFormAlert}
                />
                <InputField labelName='Email'
                    parentClass={fieldDivLeft}
                    inputClass={`${formInput} p-2.5`}
                    inputId='email'
                    value={email}
                    setValue={setEmail}
                    formAlert={formAlert}
                    setFormAlert={setFormAlert}
                />
                <SelectField labelName='State'
                    parentClass={fieldDivRight}
                    inputClass={formInput}
                    inputId='state'
                    value={state}
                    setValue={setState}
                    selectItems={auStates}
                    formAlert={formAlert}
                    setFormAlert={setFormAlert}
                />
                <SelectFacetField labelName='Select Make'
                    parentClass={fieldDivLeft}
                    inputClass={formInput}
                    inputId='make'
                    value={facetSearchFields.make}
                    selectItems={makeData}
                    formAlert={formAlert}
                    setFormAlert={setFormAlert}
                    facetSearchFields={facetSearchFields}
                    setFacetSearchFields={setFacetSearchFields}
                />
                <SelectFacetField labelName='Select Model'
                    parentClass={fieldDivRight}
                    inputClass={formInput}
                    inputId='model'
                    value={facetSearchFields.model}
                    selectItems={modelData}
                    formAlert={formAlert}
                    setFormAlert={setFormAlert}
                    facetSearchFields={facetSearchFields}
                    setFacetSearchFields={setFacetSearchFields}
                />
                <SelectFacetField labelName='Select Year'
                    parentClass={fieldDivLeft}
                    inputClass={formInput}
                    inputId='year'
                    value={facetSearchFields.year}
                    selectItems={yearData}
                    formAlert={formAlert}
                    setFormAlert={setFormAlert}
                    facetSearchFields={facetSearchFields}
                    setFacetSearchFields={setFacetSearchFields}
                />
                <SelectFacetField labelName='Select Badge'
                    parentClass={fieldDivRight}
                    inputClass={formInput}
                    inputId='badge'
                    value={facetSearchFields.badge}
                    selectItems={badgeData}
                    formAlert={formAlert}
                    setFormAlert={setFormAlert}
                    facetSearchFields={facetSearchFields}
                    setFacetSearchFields={setFacetSearchFields}
                />
                <InputField labelName='Kilometres'
                    parentClass={fieldDivLeft}
                    inputClass={`${formInput} p-2.5`}
                    inputId='kilometres'
                    value={kilometres}
                    setValue={setKilometres}
                    formAlert={formAlert}
                    setFormAlert={setFormAlert}
                />
                <div className="flex flex-wrap md:w-1/2 md:pl-4">
                    <InputField labelName='Registration Number'
                        parentClass="w-3/5 pr-3"
                        inputClass='block w-full h-10 border border-ea-gray-blue rounded-md p-2.5'
                        inputId='rego'
                        value={rego}
                        setValue={setRego}
                        formAlert={formAlert}
                        setFormAlert={setFormAlert}
                    />
                    <SelectField labelName='Registration State'
                        parentClass="w-2/5"
                        inputClass='block w-full h-10 border border-ea-gray-blue rounded-md'
                        inputId='rego-state'
                        value={regoState}
                        setValue={setRegoState}
                        selectItems={auStates}
                        formAlert={formAlert}
                        setFormAlert={setFormAlert}
                    />
                </div>
                {formAlert && formAlert !== 'success' && (
                    <div className="mt-2 w-full">
                        <Alert text={formAlert} type='error' />
                    </div>
                )}
                {formAlert === 'success' && (
                    <div className="mt-2 w-full">
                        <Alert text='Your vehicle has been submitted' type='success' />
                    </div>
                )}
                <div className='pt-5 w-full'>
                    <button type="submit"
                        className="w-full font-medium bg-ea-button rounded-md h-12 hover:bg-ea-button-hover">
                        SUBMIT
                    </button>
                </div>
            </form>
        </div>
    )
}