
import React, { useState } from 'react';
import {
  Arrow,
  AusGraphic,
  Stores
} from './images/svg_components';
import { Form } from './components/form/Form';
import AppVersionDialog from './components/Dialog';

export default function EasyAuto() {
  const [showDialog, setShowDialog] = useState<boolean>(false);

  return (
    <div className='font-poppins'>
      <nav className='bg-ea-yellow h-8'>
        <Stores />
      </nav>
      <div className='flex flex-col md:flex-row justify-around'>
        <div className='flex flex-col p-6 md:pl-20 md:pb-20 lg:pl-52'>
          <div className='text-ea-blue space-y-2'>
            <div className='lg:pb-9'>
              <a href="https://easyauto123.com.au/">
                <img src={require('./images/logo.png')}
                  alt='cars'
                  className='w-[126px] md:w-[146px] lg:w-[170px]'></img>
              </a>
            </div>
            <h2 className='text-h3 lg:text-h1 font-bold'>
              easyauto BUYING CENTRE
            </h2>
            <h4 className='text-p2 font-semibold lg:bg-ea-yellow inline-block'>
              The best way to buy and sell used cars.
            </h4>
            <div className='lg:w-4/5'>
              <p className='py-5 text-p2'>
                Easyauto 123 are part of Australia's Largest Dealer Group, ASX Listed Eagers Automotive.
              </p>
              <p className='text-p2'>
                We are the highest volume used car retailer in Australia selling over 50,000 cars per year.
              </p>
            </div>
          </div>
        </div>
        <div className='py-8 md:pb-0 flex items-center justify-center'>
          <img src={require('./images/landing_scaled.png')}
            alt='cars'
            className='md:w-[700px]'></img>
        </div>
      </div>
      <div className='relative'>
        <div className='block z-10 md:hidden absolute left-1/2 transform -translate-x-1/2 top-40 md:top-0 md:left-0 md:py-2'>
          <AusGraphic />
        </div>
        <div className='bg-ea-yellow relative flex justify-center md:flex-row pb-10 md:py-2'>
          <div className='hidden md:block absolute md:left-0 transform -translate-x-1/4'>
            <AusGraphic />
          </div>
          <h3 className='text-ea-blue sm:text-center font-semibold text-h4 lg:text-h3 w-[380px] md:w-[460px] lg:w-[900px] px-5 py-12'>
            We are currently looking to purchase quality used cars for our Hobart Used Car Superstore
          </h3>
        </div>
        <div className='flex flex-col items-center md:justify-around bg-ea-light-gray'>
          <h2 className='text-h3 md:max-lg:text-h4 font-bold text-ea-blue flex pt-32 md:pt-12 pb-11'>
            How it works
          </h2>
          <div className='flex flex-col md:justify-around max-w-6xl md:flex-row px-auto pb-14 md:space-y-0 md:space-x-7'>
            <div className='flex flex-row justify-start border-2 border-ea-yellow rounded-lg w-80 md:w-56 lg:w-80 h-48 md:h-32 lg:h-48 shadow-lg bg-white'>
              <div className='text-ea-yellow text-number md:max-lg:text-number-md font-bold pt-6 pl-6 md:max-lg:pt-3'>
                1
              </div>
              <div className='pt-6 px-5 mx-auto'>
                <h2 className='text-ea-blue pt-4 pb-5 md:max-lg:pt-1 md:max-lg:pb-3 text-h4 font-bold md:max-lg:text-p2'>
                  Submit your details
                </h2>
                <p className='text-p1 md:text-footer lg:text-p1'>
                  Enter your vehicle registration number & contact details
                </p>
              </div>
            </div>
            <Arrow />
            <div className='flex flex-row justify-start border-2 border-ea-yellow rounded-lg w-80 md:w-56 lg:w-80 h-48 md:h-32 lg:h-48 shadow-lg bg-white'>
              <div className='text-ea-yellow text-number md:max-lg:text-number-md font-bold pt-6 pl-6 md:max-lg:pt-3'>
                2
              </div>
              <div className='pt-4 px-5 mx-auto'>
                <h2 className='text-ea-blue pb-1 text-h4 font-bold md:max-lg:text-p2 pt-3 md:max-lg:pt-1'>
                  We valuate your vehicle
                </h2>
                <p className='text-p1 md:text-footer lg:text-p1'>
                  Easy Auto 123 views your advertisement and values your vehicle to offer you top market price
                </p>
              </div>
            </div>
            <Arrow />
            <div className='flex flex-row justify-start border-2 border-ea-yellow rounded-lg w-80 md:w-56 lg:w-80 h-48 md:h-32 lg:h-48 shadow-lg bg-white'>
              <div className='text-ea-yellow text-number md:max-lg:text-number-md font-bold pt-6 pl-6 md:max-lg:pt-3'>
                3
              </div>
              <div className='pt-6 lg:pt-7 px-5 mx-auto'>
                <h2 className='text-ea-blue pt-4 pb-5 md:max-lg:pt-1 md:max-lg:pb-3 text-h4 font-bold md:max-lg:text-p2'>
                  You are sent an offer
                </h2>
                <p className='text-p1 md:text-footer lg:text-p1'>
                  You receive price via text or email within 1 hour*
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col xl:flex-row'>
        <div className='flex justify-center xl:justify-end relative p-8 bg-ea-blue text-white md:max-xl:h-[260px] align-middle pb-28 xl:pr-5 space-y-4 xl:w-1/2'>
          <div className='space-y-4 xl:max-w-sm xl:pt-10 z-10'>
            <div className='text-h4 md:text-h3 xl:text-h2 md:max-xl:max-w-md font-semibold'>
              <h3 className='block md:max-xl:inline'>
                Same day quote.
              </h3>
              <span className='hidden md:max-xl:inline'>&nbsp;</span>
              <h3 className='block md:max-xl:inline'>
                Same day inspection.
              </h3>
              <span className='hidden md:max-xl:inline'>&nbsp;</span>
              <h3 className='block md:max-xl:inline'>
                Same day Payment.
              </h3>
            </div>
            <p className='text-p1'>
              Don't waste your weekend dealing with tyre-kickers!
            </p>
            <p className='text-p1'>
              Sell your car the easyauto 123 way. Simply fill out this contact form and get started.
            </p>
          </div>
          <div className='absolute bottom-0 md:bottom-5 left-0 pb-8 md:pb-0 align-middle'>
            <img src={require('./images/background_2.png')}
              alt='car'
              className='h-32 md:h-56 xl:h-80'></img>
          </div>
        </div>
        <Form />
      </div>
      <div className='bg-ea-dark-gray'>
        <footer className='flex flex-wrap justify-between md:justify-between text-white text-footer h-32 md:h-16 p-7 md:px-28 lg:max-w-[880px]'>
          <p className='w-full md:w-fit'>
            easyauto123 2021&nbsp;&nbsp;&nbsp;ACN 148 136 314
          </p>
          <div className='hidden md:block w-px bg-white'></div>
          <p className="hover:underline">
            <a href="https://easyauto123.com.au/terms-of-use"
              rel='noreferrer'
              target='_blank'>
              Terms of use
            </a>
          </p>
          <div className='hidden md:block w-px bg-white'></div>
          <p className="hover:underline">
            <a href="https://www.eagersautomotive.com.au/privacy-policy/"
              rel='noreferrer'
              target='_blank'>
              Privacy Policy
            </a>
          </p>
          <div className='hidden md:block w-px bg-white'></div>
          <button className='hover:underline'
            onClick={() => setShowDialog(true)}>
            App version v2.17.0
          </button>
        </footer>
      </div>
      <AppVersionDialog showDialog={showDialog}
        setShowDialog={() => setShowDialog(!showDialog)} />
    </div>
  )
}

