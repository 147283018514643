import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type CarDetails = {
  __typename?: 'CarDetails';
  badge: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  year: Scalars['String'];
};

export type CascadingFacetSearchInput = {
  badge?: InputMaybe<Scalars['String']>;
  facet: Scalars['String'];
  make?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['String']>;
};

export type ContactFormInput = {
  email: Scalars['String'];
  enquiry: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['String'];
  subject: Scalars['String'];
};

export type CustomerDetailsInput = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  number: Scalars['String'];
  postcode: Scalars['String'];
  report_reason: Scalars['String'];
};

export type Facet = {
  __typename?: 'Facet';
  count: Scalars['Int'];
  value: Scalars['String'];
};

export type FacetSearchInput = {
  badge: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  year: Scalars['String'];
};

export type FormFields = {
  badge: Scalars['String'];
  email: Scalars['String'];
  kilometres: Scalars['String'];
  make: Scalars['String'];
  model: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  rego: Scalars['String'];
  rego_state: Scalars['String'];
  state: Scalars['String'];
  year: Scalars['String'];
};

export type GenerateReportInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  rego?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  valuation_id: Scalars['String'];
  vin?: InputMaybe<Scalars['String']>;
};

export type MakeFacets = {
  __typename?: 'MakeFacets';
  makeResults: Array<Facet>;
};

export type Message = {
  __typename?: 'Message';
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  generate_report: ReportUrl;
  submit_contact_form: Message;
  submit_form: CarDetails;
};


export type MutationGenerate_ReportArgs = {
  input: GenerateReportInput;
};


export type MutationSubmit_Contact_FormArgs = {
  input: ContactFormInput;
};


export type MutationSubmit_FormArgs = {
  input: FormFields;
};

export type Query = {
  __typename?: 'Query';
  cascading_facet_search?: Maybe<Array<Maybe<Facet>>>;
  facet_vehicle_search: Array<VehicleDetails>;
  get_valuation: ValuationId;
  lookup_vehicle: Array<VehicleDetails>;
  make_facet_search?: Maybe<MakeFacets>;
};


export type QueryCascading_Facet_SearchArgs = {
  input: CascadingFacetSearchInput;
};


export type QueryFacet_Vehicle_SearchArgs = {
  input: FacetSearchInput;
};


export type QueryGet_ValuationArgs = {
  input: ValuationInput;
};


export type QueryLookup_VehicleArgs = {
  rego_or_vin: Scalars['String'];
  state: Scalars['String'];
};

export type ReportUrl = {
  __typename?: 'ReportUrl';
  url: Scalars['String'];
};

export type ValuationId = {
  __typename?: 'ValuationId';
  valuation_id: Scalars['String'];
};

export type ValuationInput = {
  rego?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  vehicle_id: Scalars['String'];
  vin?: InputMaybe<Scalars['String']>;
};

export type VehicleDetails = {
  __typename?: 'VehicleDetails';
  composite_title: Scalars['String'];
  vehicle_id: Scalars['String'];
  vehicle_title: Scalars['String'];
  vin?: Maybe<Scalars['String']>;
  year: Scalars['String'];
};

export type CascadingFacetSearchQueryVariables = Exact<{
  input: CascadingFacetSearchInput;
}>;


export type CascadingFacetSearchQuery = { __typename?: 'Query', cascading_facet_search?: Array<{ __typename?: 'Facet', value: string, count: number } | null> | null };

export type SubmitFormMutationVariables = Exact<{
  input: FormFields;
}>;


export type SubmitFormMutation = { __typename?: 'Mutation', submit_form: { __typename?: 'CarDetails', make: string, model: string, year: string, badge: string } };

export type MakeFacetSearchQueryVariables = Exact<{ [key: string]: never; }>;


export type MakeFacetSearchQuery = { __typename?: 'Query', make_facet_search?: { __typename?: 'MakeFacets', makeResults: Array<{ __typename?: 'Facet', value: string, count: number }> } | null };


export const CascadingFacetSearchDocument = gql`
    query CascadingFacetSearch($input: CascadingFacetSearchInput!) {
  cascading_facet_search(input: $input) {
    value
    count
  }
}
    `;

/**
 * __useCascadingFacetSearchQuery__
 *
 * To run a query within a React component, call `useCascadingFacetSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useCascadingFacetSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCascadingFacetSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCascadingFacetSearchQuery(baseOptions: Apollo.QueryHookOptions<CascadingFacetSearchQuery, CascadingFacetSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CascadingFacetSearchQuery, CascadingFacetSearchQueryVariables>(CascadingFacetSearchDocument, options);
      }
export function useCascadingFacetSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CascadingFacetSearchQuery, CascadingFacetSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CascadingFacetSearchQuery, CascadingFacetSearchQueryVariables>(CascadingFacetSearchDocument, options);
        }
export type CascadingFacetSearchQueryHookResult = ReturnType<typeof useCascadingFacetSearchQuery>;
export type CascadingFacetSearchLazyQueryHookResult = ReturnType<typeof useCascadingFacetSearchLazyQuery>;
export type CascadingFacetSearchQueryResult = Apollo.QueryResult<CascadingFacetSearchQuery, CascadingFacetSearchQueryVariables>;
export const SubmitFormDocument = gql`
    mutation SubmitForm($input: FormFields!) {
  submit_form(input: $input) {
    make
    model
    year
    badge
  }
}
    `;
export type SubmitFormMutationFn = Apollo.MutationFunction<SubmitFormMutation, SubmitFormMutationVariables>;

/**
 * __useSubmitFormMutation__
 *
 * To run a mutation, you first call `useSubmitFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFormMutation, { data, loading, error }] = useSubmitFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitFormMutation(baseOptions?: Apollo.MutationHookOptions<SubmitFormMutation, SubmitFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitFormMutation, SubmitFormMutationVariables>(SubmitFormDocument, options);
      }
export type SubmitFormMutationHookResult = ReturnType<typeof useSubmitFormMutation>;
export type SubmitFormMutationResult = Apollo.MutationResult<SubmitFormMutation>;
export type SubmitFormMutationOptions = Apollo.BaseMutationOptions<SubmitFormMutation, SubmitFormMutationVariables>;
export const MakeFacetSearchDocument = gql`
    query MakeFacetSearch {
  make_facet_search {
    makeResults {
      value
      count
    }
  }
}
    `;

/**
 * __useMakeFacetSearchQuery__
 *
 * To run a query within a React component, call `useMakeFacetSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useMakeFacetSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMakeFacetSearchQuery({
 *   variables: {
 *   },
 * });
 */
export function useMakeFacetSearchQuery(baseOptions?: Apollo.QueryHookOptions<MakeFacetSearchQuery, MakeFacetSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MakeFacetSearchQuery, MakeFacetSearchQueryVariables>(MakeFacetSearchDocument, options);
      }
export function useMakeFacetSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MakeFacetSearchQuery, MakeFacetSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MakeFacetSearchQuery, MakeFacetSearchQueryVariables>(MakeFacetSearchDocument, options);
        }
export type MakeFacetSearchQueryHookResult = ReturnType<typeof useMakeFacetSearchQuery>;
export type MakeFacetSearchLazyQueryHookResult = ReturnType<typeof useMakeFacetSearchLazyQuery>;
export type MakeFacetSearchQueryResult = Apollo.QueryResult<MakeFacetSearchQuery, MakeFacetSearchQueryVariables>;