import { Facet } from '../../graphql/generated';
import {
    FacetOptions,
    FacetSearchParams,
    Regions
} from './Form';

const formLabel: string = 'mb-1 text-p2 font-medium text-ea-blue';

interface CommonFieldProps {
    labelName: string;
    parentClass: string;
    inputClass: string;
    inputId: string;
    value: string;
    formAlert: string | null;
    setFormAlert: (value: string | null) => void;
}

interface InputFieldProps extends CommonFieldProps {
    setValue: (value: string) => void;
}

interface SelectFieldProps extends InputFieldProps {
    selectItems: Facet[] | Regions[] | null;
}

interface SelectFacetFieldProps extends CommonFieldProps {
    selectItems: Facet[] | Regions[] | null;
    facetSearchFields: FacetSearchParams;
    setFacetSearchFields: (value: FacetSearchParams) => void;
}

export function InputField({
    labelName,
    parentClass,
    inputClass,
    inputId,
    value,
    setValue,
    formAlert,
    setFormAlert
}: InputFieldProps) {
    return (
        <div className={parentClass}>
            <label htmlFor={inputId}
                className={formLabel}>
                {labelName}
            </label>
            <input type='text'
                id={inputId}
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    if (formAlert) setFormAlert(null);
                }}
                className={inputClass} />
        </div>
    )
}

export function SelectField({
    labelName,
    parentClass,
    inputClass,
    inputId,
    value,
    setValue,
    formAlert,
    setFormAlert,
    selectItems
}: SelectFieldProps) {
    return (
        <div className={parentClass}>
            <label htmlFor={inputId}
                className={formLabel}>
                {labelName}
            </label>
            <select id={inputId}
                value={value}
                className={inputClass}
                onChange={(e) => {
                    setValue(e.target.value);
                    if (formAlert) setFormAlert(null);
                }}
            >
                {selectItems?.map((r, i) => (
                    <option value={r.value} key={i}>
                        {r.value}
                    </option>
                ))}
            </select>
        </div>
    )
}

export function SelectFacetField({
    labelName,
    parentClass,
    inputClass,
    inputId,
    value,
    formAlert,
    setFormAlert,
    selectItems,
    facetSearchFields,
    setFacetSearchFields
}: SelectFacetFieldProps) {
    const updateFacetSearch = (value: string, id: FacetOptions) => {
        const {
            make,
            model,
            year } = facetSearchFields;

        switch (id) {
            case 'make':
                setFacetSearchFields({
                    facet: 'model',
                    make: value,
                    model: '',
                    year: '',
                    badge: ''
                });
                break;
            case 'model':
                setFacetSearchFields({
                    facet: 'year',
                    make,
                    model: value,
                    year: '',
                    badge: ''
                });
                break;
            case 'year':
                setFacetSearchFields({
                    facet: 'badge',
                    make,
                    model,
                    year: value,
                    badge: ''
                });
                break;
            case 'badge':
                setFacetSearchFields({
                    facet: 'badge',
                    make,
                    model,
                    year,
                    badge: value
                });
                break;
        }
    }

    const facets = ['make', 'model', 'year', 'badge'];
    const disabled = facets.indexOf(inputId) > facets.indexOf(facetSearchFields.facet);

    return (
        <div className={parentClass}>
            <label htmlFor={inputId}
                className={formLabel}>
                {labelName}
            </label>
            <select id={inputId}
                value={value}
                className={inputClass}
                onChange={({ target }) => {
                    updateFacetSearch(target.value, target.id as FacetOptions);
                    if (formAlert) setFormAlert(null);
                }}
                disabled={disabled}
            >
                <option value=''
                    disabled={true}>
                    Select {`${inputId.charAt(0).toUpperCase()}${inputId.substring(1)}`}
                </option>
                {selectItems?.map((r, i) => (
                    <option value={r.value} key={i}>
                        {r.value}
                    </option>
                ))}
            </select>
        </div>
    )
}

