import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import './index.css';
import EasyAuto from './App';

const client = new ApolloClient({
    uri: process.env.REACT_APP_CLIENT_WEB_URI,
    cache: new InMemoryCache(),
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
    .render(
        <ApolloProvider client={client}>
            <EasyAuto />
        </ApolloProvider>);



